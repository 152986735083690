import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { LINE_PERFORMANCE_UNSCHEDULED_TIME } from "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Unscheduled Time", "search-by": "FacilityName,ProductionLineCode,Reason", "ph-search-by": "Search by keywords", "fetch-on-first-mount": false } }, [_c("text-field", { key: "FacilityName", attrs: { "data-index": "facilityName", "title": "Facility Name" } }), _c("text-field", { key: "ProductionLineCode", attrs: { "data-index": "productionLineCode", "title": "Line" } }), _c("text-field", { key: "StartTime", attrs: { "data-index": "startTime", "title": "Start Time", "parse": _vm.parseDateTime } }), _c("text-field", { key: "EndTime", attrs: { "data-index": "endTime", "title": "EndTime", "parse": _vm.parseDateTime } }), _c("text-field", { key: "TotalUnscheduledTime", attrs: { "data-index": "totalUnscheduledTime", "title": "Total Unscheduled Time (mins)", "parse": _vm.parseTotalUnscheduledTime } }), _c("text-field", { key: "Reason", attrs: { "data-index": "reason", "title": "Reason" } })], 1);
};
var staticRenderFns$1 = [];
var UnscheduledDowntime_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const __vue2_script$1 = {
  name: "UnscheduledDowntime",
  inject: ["crud", "getArea"],
  computed: {
    area() {
      return this.getArea();
    }
  },
  watch: {
    area(newVal) {
      this.crud.setFilter("AreaId", {
        operator: "Eq",
        value: newVal ? newVal : -1
      });
      this.fetchList();
    }
  },
  created() {
    this.crud.setFilter("AreaId", {
      operator: "Eq",
      value: this.area ? this.area : -1
    });
    this.fetchList();
  },
  methods: {
    parseDateTime(value) {
      return this.$moment(value).format("DD/MM/YYYY HH:mm");
    },
    parseTotalUnscheduledTime(value) {
      return value + " mins";
    },
    fetchList() {
      const { count, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ count, page: 1, pageSize });
      this.crud.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var UnscheduledDowntime = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("resource", { attrs: { "name": "line-performance.unscheduleddowntimes", "api-url": _vm.apiUrl, "create-route": "/line-performance/unscheduled-time/create", "edit-route": "/line-performance/unscheduled-time/:id", "page": _vm.page } }, [_c("unscheduled-downtime")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    UnscheduledDowntime
  },
  data() {
    return {
      page: LINE_PERFORMANCE_UNSCHEDULED_TIME,
      apiUrl,
      menuItems: [
        {
          key: "unscheduled-time",
          title: "Unscheduled Time",
          path: "/line-performance/unscheduled-time"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
